import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from 'src/app/auth/services/logIn.service';
import { UserService } from 'src/app/users/services/user.service';
import { PaymentMethodsComponent } from '../payment-methods/payment-methods.component';
import { TranslateService } from '@ngx-translate/core';
import { GeneralPaymentService } from '../../services/general-payment.service';
import { PaypalPaymentService } from '../../services/paypal-payment.service';
import { CommonResponse } from 'src/app/shared/models/reponse.model';

@Component({
  selector: 'app-shipping-form',
  templateUrl: './shipping-form.component.html',
  styleUrls: ['./shipping-form.component.scss']
})
export class ShippingFormComponent implements OnInit {
  shippingForm: FormGroup;
  billingForm: FormGroup;
  country: string = '';
  isBillingFormDisabled = true;
  isProcessingPayment: boolean = false;
  userId: number;
  orderPayment: any;

  @ViewChild(PaymentMethodsComponent) paymentMethodsComponent!: PaymentMethodsComponent;

  constructor(
    private fb: FormBuilder,
    private _loginService: LoginService,
    private _userService: UserService,
    private translate: TranslateService,
    private paymentService: GeneralPaymentService,
    private _paypalService: PaypalPaymentService
  ) { }

  
  ngOnInit(): void {
    this.shippingForm = this.fb.group({
      personType: ['', Validators.required],
      fullName: ['', Validators.required],
      idNumber: ['', Validators.required],
      country: ['', Validators.required],
      address: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]]
    });

    this.billingForm = this.fb.group({
      billingPersonType: [{ value: '', disabled: true }],
      billingFullName: [{ value: '', disabled: true }],
      billingIdNumber: [{ value: '', disabled: true }],
      billingCountry: [{ value: '', disabled: true }],
      billingAddress: [{ value: '', disabled: true }],
      billingEmail: [{ value: '', disabled: true }]
    });

    this.shippingForm.valueChanges.subscribe(() => {
      this.toggleBillingFields();
      this.updateBillingLabels();
    });

    this.country = localStorage.getItem('countryName') || '';


    this.checkCountryAndChangeTexts();

    this.paymentService.paymentProcessing$.subscribe(isProcessing => {
      this.isProcessingPayment = isProcessing;
    });

    const loggedUser = sessionStorage.getItem('logged_user');
    let userId: number = 0; // Valor predeterminado

    if (loggedUser) {
      try {
        const parsedData = JSON.parse(loggedUser);
        userId = parseInt(parsedData.userId, 10); // Convertir a número
      } catch (error) {
        console.error('Error parsing userId from sessionStorage', error);
      }
    }

    this.userId = userId;
  }

  ngAfterViewInit() {
    if (this.paymentMethodsComponent) {
      this.paymentMethodsComponent.paymentProcessed.subscribe(() => {
        this.submitShippingInformation();
      });
    }
  }

  updateBillingLabels(): void {
    const personType = this.shippingForm.get('personType')?.value;
    if (personType === 'company') {
      this.translate.setTranslation('es', {
        shipping: {
          identity_document: "NIT",
          document: "Número de identificación de empresa"
        }
      }, true);
    } else {
      this.translate.setTranslation('es', {
        shipping: {
          identity_document: "DNI/NIE",
          document: "Documento de identidad"
        }
      }, true);
    }
  }

  checkCountryAndChangeTexts(): void {
    if (this.country == 'España') {

      this.translate.setTranslation('es', {
        shipping: {
          full_name: 'Nombre completo',
          shipping_information: "Datos de envío",
          personal_information: "Información personal",
          name: "Nombre",
          identity_document: "DNI/NIE",
          document: "Documento",
          country: "País",
          address: "Dirección",
          email: "Correo electrónico",
          billing_information: "Datos de facturación",
          fill_in_information_with_personal_data: "Rellenar con los datos personales",
          person_type: "Tipo de persona",
          natural_person: "Persona física",
          company: "Empresa",
          how_do_you_want_to_pay: "¿Cómo deseas pagar?",
          pay: "Pagar"
        }
      }, true);
    }
  }


  copyPersonalData(event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      const personType = this.shippingForm.get('personType')?.value || '';
      const fullName = this.shippingForm.get('fullName')?.value || '';
      const idNumber = this.shippingForm.get('idNumber')?.value || '';
      const country = this.shippingForm.get('country')?.value || '';
      const address = this.shippingForm.get('address')?.value || '';
      const email = this.shippingForm.get('email')?.value || '';

      this.billingForm.patchValue({
        billingPersonType: personType,
        billingFullName: fullName,
        billingIdNumber: idNumber,
        billingCountry: country,
        billingAddress: address,
        billingEmail: email
      });

      // Deshabilitar el formulario de facturación
      this.billingForm.disable();
    } else {
      // Habilitar el formulario de facturación
      this.billingForm.enable();
      this.billingForm.reset();
    }
  }


  toggleBillingFields(): void {
    const allPersonalFieldsValid = Object.values(this.shippingForm.controls).every(control => control.valid);
    this.isBillingFormDisabled = !allPersonalFieldsValid;
    if (allPersonalFieldsValid) {
      this.billingForm.enable();
    } else {
      this.billingForm.disable();
    }
  }

  submitShippingInformation() {
    // interface PaymentResponse {
    //   data: {
    //     payment_redirect_link: string;
    //     orderPayment: number;
    //   };
    // }

    // this._paypalService.startPayment().subscribe(
    //   (response: CommonResponse<unknown>) => {
    //     if (response.data && typeof response.data == 'object' && 'orderPayment' in response.data) {
    //       const paymentData = response.data as PaymentResponse['data'];
    //       this.orderPayment = paymentData.orderPayment; 
    //     } else {
    //       console.error("La respuesta no tiene el formato esperado o 'data' es undefined.");
    //     }
    //   },
    //   error => {
    //     console.error(error);
    //   }
    // );
  

    const shippingData = this.shippingForm.value;
    const billingData = this.billingForm.value;

    const combinedData = {
      userId: this.userId,
      shipping: shippingData,
      billing: billingData,
      orderPayment: this.orderPayment
    };

    this._userService.userShippingInfo(combinedData).subscribe((response: any) => {

    }, (error: any) => {
      console.error('Error submitting data:', error);
    });
  }
}
